/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;

/**
 * Custom base styles, applied after the tailwind-base classes
 */

html {
  font-size: 62.5%;
  font-family: "Inter var", Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #121212;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
}

body {
  font-size: 13px;
  line-height: 1.4;
  overflow-x: hidden;
}

html,
body,
#root {
  position: relative;
  margin: 0;
  min-height: 100vh;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

[role="tooltip"] {
  z-index: 9999;
}
@media (min-width: 992px) {
  td.MuiTableCell-body,
  th.MuiTableCell-head {
    display: table-cell !important;
  }
}
/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
  transition: none !important;
  animation: none !important;
}

button:focus {
  outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transitiondelay: 9999s;
  transitionproperty: background-color, color;
}

:focus {
  outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
  height: 1px !important;
}

/** Custom CSS */
.darkFontColor {
  color: #421432 !important;
  margin-bottom: 5px;
}
.darkFontColor:hover {
  color: #e91aa1 !important;
}
.floatingAddBtn {
  position: fixed;
  right: 30px;
  bottom: 100px;
}
.rdw-editor-wrapper {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}
.rdw-editor-toolbar {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important ;
}
.public-DraftStyleDefault-block {
  min-height: 200px;
  padding: 10px;
}

.deleteImageFromInput {
  position: absolute;
  top: 0;
  right: 0;
  background: #6b2c57;
  padding: 5px;
  border-top-right-radius: 20px;
  cursor: pointer;
  padding-right: 7px;
  padding-top: 7px;
  border-bottom-left-radius: 20px;
}
.deleteImageFromInput span {
  color: #fff;
  font-size: 20px;
}

.FusePageCarded-content {
  position: static !important;
  height: auto !important;
  min-height: 100vh;
  display: block !important;
}
.ps__rail-x {
  display: none !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  max-width: 100%;
}
.FusePageCarded-content {
  min-height: calc(100vh - 300px);
  overflow-x: auto;
}
.MuiTable-root {
  overflow: auto;
  max-width: 100%;
}
.mt-64 {
  overflow-x: auto;
}
.muiltr-1w79wd5 {
  width: inherit;
}

.FusePageCarded-header {
  height: auto !important;
  /*min-height: auto !important;
  max-height: none !important;*/
}

.FusePageCarded-topBg {
  height: 250px !important;
}

@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}
.FusePageSimple-header-x{
  border-radius: 0 !important;
  margin: 0 !important;
}

.min-width-auto{
  min-width: auto !important;
}