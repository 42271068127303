/**
Basic Table Styles
 */
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
    width: 100%;
    border: none;
    border-spacing: 0;
    text-align: left;
}

table.simple thead tr th {
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
}

table.simple thead tr th:first-child {
}

table.simple thead tr th:last-child {
}

table.simple tbody tr td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.simple tbody tr td:first-child {
}

table.simple tbody tr td:last-child {
}

table.simple tbody tr:last-child td {
    border-bottom: none;
}

table.simple.clickable tbody tr {
    cursor: pointer;
}

table.simple.clickable tbody tr:hover {
    background: rgba(0, 0, 0, 0.03);
}

table.simple.borderless {
    border: none;
}

table.simple.borderless tbody tr td{
    border: none;
}

table.simple.borderless thead tr th{
    border: none;
}
